import { useState, useEffect } from "react";
import axios, { Axios } from "axios";
import { useForm } from "react-hook-form";
import { apiurl } from "configs/config";
import ApiClient from "Service/ApiClient";
import { Listitem } from "../../../Redux/Items/action";
import { useDispatch, useSelector } from "react-redux";
import toast, { ToastBar } from "react-hot-toast";
import { VazhipaduFormSchema } from "Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { getValue } from "@mui/system";
import SelectInput from "@mui/material/Select/SelectInput";
import { startLoader, stopLoader } from "../../../Redux/Common/Loader/action";
import { number } from "zod";

function VazhipaduMapping() {
  const dispatch = useDispatch();
  const TempleId = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const [FullData, setFullData] = useState([]);
  const [selectedSeva, setselectedSeva] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [sevaName, setSevaName] = useState(null);
  const [Item, setItem] = useState([]);
  const [Id, setId] = useState(0);
  const [showmodal, setshowmodal] = useState(false);
  const [daleteId, setdaleteId] = useState(null);
  const [sevaId, setSevaId] = useState("");
  const [input, setInput] = useState("");
  const ItemData = useSelector((state) => state.Items.ListItemData);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(VazhipaduFormSchema),
    defaultValues: {
      ServiceId: "",
      Quantity: "",
    },
  });

  useEffect(() => {
    setId(0);
  }, [sevaId]);
const [edit, setedit] = useState(false)
  const handleSave = () => {
    axios
      .post(
        `${apiurl}/api/SevaItemMapper/SetMapper?TempleId=${TempleId}`,
        TableData
      )
      .then((response) => {
    
        if (response) {
          toast.success("Item mapped successfully");
        }
        // if (response.data.StatusCode === 200 && formattedData.Id >0) {
        //   toast.success("Mapping Updated successfully");
        // }
      })
      .catch((error) => {
       
        toast.error("Error ", error);
      });

    handleclear();
  };
  
  const onSubmit = (data) => {
    // Convert data.ServiceId to a number
    const serviceId = parseInt(data.ServiceId);
  
    // Find itemName from ItemData based on itemId
    const selectedItem = ItemData.find((item) => item.Id === serviceId);
    const itemName = selectedItem ? selectedItem.Name : "";
  
    // Check if an item with the same index already exists in TableData
    const existingIndex = TableData.findIndex((item) => item.Id === Id);
  
    const formattedData = {
      Id: Id,
      SevaId: sevaId,
      TempleId: TempleId,
      ItemId: serviceId, // Use converted serviceId
      Qty: parseInt(data.Quantity),
      SevaName: sevaName,
      Item: itemName,
    };
  
    if (existingIndex !== -1) {
         // If the item already exists, update it
      const updatedTableData = [...TableData];
      updatedTableData[existingIndex] = formattedData;
      setTableData(updatedTableData);
      toast.success("Updated Successfully");
    } else {
     
      // If the item doesn't exist, add it to TableData
      setTableData([...TableData, formattedData]);
    }
  
    setId(0);
    reset();
  };
  

  
  const handleclear = () => {
    setSevaId("");
    setId(0);
    reset();
    setSevaName(null);
    setshowmodal(false);
    setdaleteId(null);
    setTableData([]);
 
  };
  useEffect(() => {
    dispatch(
      Listitem({
        TempleId: TempleId,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(startLoader());
    axios
      .get(
        `${apiurl}/api/SevaItemMapper/GetSevaWithTempleId?TempleId=${TempleId}`
      )
      .then((response) => {
        setFullData(response.data.Result);
      })
      .catch((error) => {
      
      });
    dispatch(stopLoader());
  }, [TempleId]);

  useEffect(() => {

    if (sevaId > 0) {
      dispatch(startLoader());
      axios.get(
        `${apiurl}/api/SevaItemMapper/GetItemSevaMapperData?TempleId=${TempleId}&SevaId=${sevaId}`
      )
        .then((response) => {
          setTableData(response.data.Result.Result);
          
          setedit(false)

        })
        .catch((error) => {
        
        });
      dispatch(stopLoader());
    } else {
      return;
    }
  }, [TempleId, sevaId]);
  useEffect(()=>{
    console.log("tabledata",TableData);
  })

  const handleSevaClick = (sevaId) => {
    
    setSevaId(sevaId);
    setselectedSeva(true);
    const selectedSeva = FullData.find((item) => item.Id === sevaId);
    if (selectedSeva) {
      setSevaName(selectedSeva.SevaName);
    } else {
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filteredData = FullData.filter((item) =>
      item.SevaName.toLowerCase().includes(searchValue)
    );
    setFullData(filteredData);
  };
 

  useEffect(() => {
    if (errors.ServiceId) {
      toast.error(errors.ServiceId.message);
    }
    if (errors.Quantity) {
      toast.error(errors.Quantity.message);
    }
  }, [errors]);


  const handleedit = (item) => {
   
    setId(item.Id); // Set the ID for editing
    setSevaId(item.SevaId); // Set the SevaId for editing
    setSevaName(item.SevaName);

    setedit(true)
    // Assuming you have ItemData available and it contains the correct items
    const selectedItem = ItemData.find(
      (dataItem) => dataItem.Name === item.Item
    );

    if (selectedItem) {
      setValue("ServiceId", `${selectedItem.Id}`);
    }
   
  setValue("Quantity", item.Qty.toString()); // Set the quantity in the input field
   
  };
  const [Name, setName] = useState("");
  const handleDeleteconfirm = (item, index) => {
  
    setshowmodal(true);
    setdaleteId({
      item,
      index,
    });
    setName(item.Item);
  };

  const handleDelete = () => {
   
    axios.post(
      `${apiurl}/api/SevaItemMapper/DeleteItemMapping?TempleId=${TempleId}&Id=${daleteId.item.Id}`
    )
      .then((response) => {
        toast.success("Deleted Successfully");
        

        // Filter out the deleted item from TableData
        const updatedTableData = TableData.filter(
          (item, index) => index !== daleteId.index
        );
        setTableData(updatedTableData);

        // After deleting, close the modal
        setshowmodal(false);
      })
      .catch((error) => {
       
      });
    setName("");
  };

 

  const filteredData = FullData.filter((item) =>
    item.SevaName.toLowerCase().includes(input)
  );

  return (
    <div className="mt-5 flex">
      <div className="w-1/3 ">
        <div className="h-[80dvh]  flex-grow flex-row items-center rounded-[20px] border border-blue-200 bg-gray-200">
          <div className="p-4 ">
            <input
              type="text"
              placeholder="Search Seva Names"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="block w-full rounded-lg border border-green-500 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
            />
          </div>
          <div className="h-[300px] overflow-y-scroll ">
            <div className="w-full px-2 ">
              <div className="mb-3 ">
                <h1 className="ml-2  flex">Select seva...</h1>
              </div>
              <div className="   space-y-2 ">
                {filteredData.map((item) => (
                  <div
                    key={item.Id}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSevaClick(item.Id)}
                    className=" p-2"
                  >
                    <div className="block w-full rounded-lg border border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 hover:shadow-md focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500">
                      {item.SevaName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-2/3">
        <div className="flex items-center rounded-[20px] border border-blue-200">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="container mx-auto "
          >
            <h1
              className={`mt-4 flex justify-center ${
                sevaName ? "text-2xl font-bold" : "text-red-500"
              }`}
            >
              {sevaName ?? "Please select a Vazhipadu !"}
            </h1>
            <div className="flex w-full space-x-3 p-4 ">
              <div className="flex-1">
                {/* <select
                  className={`block w-full rounded-lg border bg-gray-50 p-2.5 text-sm text-gray-900 outline-none focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 ${
                    errors.ServiceId ? " border-red-500" : " border-gray-700"
                  }`}
                  disabled={!sevaName}
                  aria-label="Default select example"
                  {...register("ServiceId")}
                >
                  <option selected value={""}>
                    Choose a Item
                  </option>
                  {Array.isArray(ItemData) &&
                    ItemData.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                </select> */}
<select
  className={`block w-full rounded-lg border bg-gray-50 p-2.5 text-sm text-gray-900 outline-none focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 ${
    errors.ServiceId ? " border-red-500" : " border-gray-700"
  }`}
  disabled={!sevaName}
  aria-label="Default select example"
  {...register("ServiceId")}
>
  <option selected={!edit} value={""}>
    Choose a Item
  </option>
  {Array.isArray(ItemData) &&
    ItemData.map((item, index) => (
      <option
        key={index}
        value={item.Id}
        selected={edit && item.Id === getValues("ServiceId")} // Select the currently edited item
        disabled={
          TableData.some((mappedItem) => mappedItem.Item === item.Name) &&
          item.Id !== getValues("ServiceId") // Disable items already mapped to Vazhipadu, except the currently edited one
        }
      >
        {item.Name}
      </option>
    ))}
</select>


              </div>
              <div className="flex-1">
                <input
                  type="text"
                  className={`block w-full rounded-lg border bg-gray-50 p-2.5 text-sm text-gray-900 outline-none focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 ${
                    errors.Quantity ? " border-red-500" : " border-gray-700"
                  }`}
                  disabled={!sevaName}
                  onWheel={(e) => e.target.blur()}
                  placeholder="Quantity"
                  {...register("Quantity")}
                />
              </div>
            </div>
            <div className="mb-4 flex w-full justify-end px-4">
              <button
                type="submit"
                disabled={!sevaName}
                className="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {Id > 0 ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
        <div className="mb-2 flex w-full flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block w-full p-1.5 align-middle">
              <div className="overflow-x-auto rounded-lg border">
                <table className="min-w-full divide-y divide-gray-200  bg-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-bold uppercase text-gray-500"
                      >
                        Sl No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-bold uppercase text-gray-500"
                      >
                        Seva Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-bold uppercase text-gray-500"
                      >
                        Item Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-bold uppercase text-gray-500"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-right text-xs font-bold uppercase text-gray-500"
                      >
                        Edit
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-right text-xs font-bold uppercase text-gray-500"
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {Array.isArray(TableData) &&
                      TableData.map((item, index) => (
                        <tr key={item.Id}>
                          <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-800">
                            {index + 1}{" "}
                            {/* Adding 1 to the index to start from 1 */}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800">
                            {item.SevaName}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800">
                            {item.Item}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800">
                            {item.Qty}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                            <a
                              className="text-green-500 hover:text-green-700"
                              href="#"
                              onClick={() => handleedit(item)}
                            >
                              Edit
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                            <a
                              className="text-red-500 hover:text-red-700"
                              href="#"
                              onClick={() => handleDeleteconfirm(item, index)}
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>

                  {showmodal ? (
                    <>
                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                          className="fixed inset-0 h-full w-full bg-black opacity-40"
                          onClick={() => setshowmodal(false)}
                        ></div>
                        <div className="flex min-h-screen items-center px-4 py-8">
                          <div className="relative mx-auto w-full max-w-lg rounded-md bg-white p-4 shadow-lg">
                            <div className="mt-3 ">
                              <div className="mx-auto flex h-12 w-12 flex-none items-center justify-center rounded-full bg-red-100">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-10 w-10 text-red-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                <h4 className=" text-center text-xl font-medium text-gray-800">
                                 Do you want to delete this Data?
                                </h4>
                                
                                {/* <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                                div Do you realy want to delete this
                                </p> */}
                                <div className="mt-3 items-center gap-2 sm:flex">
                                  <button
                                    className="mt-2 w-full flex-1 rounded-md bg-red-600 p-2.5 text-white outline-none ring-red-600 ring-offset-2 focus:ring-2"
                                    onClick={() => handleDelete()}
                                  >
                                    Delete
                                  </button>
                                  <button
                                    className="mt-2 w-full flex-1 rounded-md border p-2.5 text-gray-800 outline-none ring-indigo-600 ring-offset-2 focus:ring-2"
                                    onClick={() => setshowmodal(false)}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full  justify-end ">
        <button
    type="submit"
    onClick={() => handleSave()}
    disabled={!TableData.length}
    // disabled={!sevaName}
    class={`mb-2 flex rounded-lg px-5 py-2.5 text-sm font-medium me-2 focus:outline-none 
            ${!TableData.length ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 
            'bg-blue-700 text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'}`}
>
    save
</button>

        </div>
      </div>
    </div>
  );
}

export default VazhipaduMapping;
