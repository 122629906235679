export const actionType = {
    STOCK_REPORT:"STOCK_REPORT",
    STOCK_REPORT_SUCCESS:"STOCK_REPORT_SUCCESS",
    STOCK_REPORT_ERROR:"STOCK_REPORT_ERROR",

   
    RECIPES_REPORT:"RECIPES_REPORT",
    RECIPES_REPORT_SUCCESS:"RECIPES_REPORT_SUCCESS",
    RECIPES_REPORT_ERROR:"RECIPES_REPORT_ERROR",
   
    SECTION_MAPPER:"SECTION_MAPPER",
    SECTION_MAPPER_SUCCESS:"SECTION_MAPPER_SUCCESS",
    SECTION_MAPPER_ERROR:"SECTION_MAPPER_ERROR",
   
    TRANSACTION_DETAILS:"TRANSACTION_DETAILS",
    TRANSACTION_DETAILS_SUCCESS:"TRANSACTION_DETAILS_SUCCESS",
    TRANSACTION_DETAILS_ERROR:"TRANSACTION_DETAILS_ERROR",
   
    APPROVAL:"APPROVAL",
    APPROVAL_SUCCESS:"APPROVAL_SUCCESS",
    APPROVAL_ERROR:"APPROVAL_ERROR",
   
    APPROVAL_LIST:"APPROVAL_LIST",
    APPROVAL_LIST_SUCCESS:"APPROVAL_LIST_SUCCESS",
    APPROVAL_LIST_ERROR:"APPROVAL_LIST_ERROR",

   


    
}

export const StockReport = (payload) => {
    return {
      type: actionType.STOCK_REPORT,
      payload,
    };
  };
  export const StockReportSucces = (payload) => {
    return {
      type: actionType.STOCK_REPORT_SUCCESS,  
      payload,
    };
  };
  export const StockReportError = (payload) => {
    return {
      type: actionType.STOCK_REPORT_ERROR,
      payload,
    };
  };



export const sectionmapper = (payload) => {
    return {
      type: actionType.SECTION_MAPPER,
      payload,
    };
  };
  export const sectionmapperSucces = (payload) => {
    return {
      type: actionType.SECTION_MAPPER_SUCCESS,  
      payload,
    };
  };
  export const sectionmapperError = (payload) => {
    return {
      type: actionType.SECTION_MAPPER_ERROR,
      payload,
    };
  };


export const RecipesReport = (payload) => {
    return {
      type: actionType.RECIPES_REPORT,
      payload,
    };
  };
  export const RecipesReportSucces = (payload) => {
    return {
      type: actionType.RECIPES_REPORT_SUCCESS,  
      payload,
    };
  };
  export const RecipesReportError = (payload) => {
    return {
      type: actionType.RECIPES_REPORT_ERROR,
      payload,
    };
  };


export const TransactionReport = (payload) => {
    return {
      type: actionType.TRANSACTION_DETAILS,
      payload,
    };
  };
  export const TransactionReportSucces = (payload) => {
    return {
      type: actionType.TRANSACTION_DETAILS_SUCCESS,  
      payload,
    };
  };
  export const TransactionReportError = (payload) => {
    return {
      type: actionType.TRANSACTION_DETAILS_ERROR,
      payload,
    };
  };



export const approval = (payload) => {
    return {
      type: actionType.APPROVAL,
      payload,
    };
  };
  export const approvalSucces = (payload) => {
    return {
      type: actionType.APPROVAL_SUCCESS,  
      payload,
    };
  };
  export const approvalError = (payload) => {
    return {
      type: actionType.APPROVAL_ERROR,
      payload,
    };
  };



export const approvalList = (payload) => {
    return {
      type: actionType.APPROVAL_LIST,
      payload,
    };
  };
  export const approvalListSucces = (payload) => {
    return {
      type: actionType.APPROVAL_LIST_SUCCESS,  
      payload,
    };
  };
  export const approvalListError = (payload) => {
    return {
      type: actionType.APPROVAL_LIST_ERROR,
      payload,
    };
  };


