
import { IoMdHome, IoMdList } from "react-icons/io";
import { MdBarChart, MdDashboard, MdBookmark } from "react-icons/md";
import { IoMdBookmarks } from "react-icons/io";
import Widget from "components/widget/Widget";

import Widget2 from "components/widget/Widget2";
import { FcSalesPerformance } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { TotalView } from "../../../Redux/Master/action";
import { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";


const Dashboard = () => {
  const handleNavigate = (path) => {
    window.location.href = path;
  };
const [apiToken, setApiToken] = useState('');


  const dispatch =useDispatch()
  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const totalviewvalue =useSelector((state)=>state.Master.viewdata)
  
  useEffect(()=>{
dispatch(TotalView({
  view:TempleID
}))
  },[])
  return (
    <div>
       
  
    


      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Issue"}
          to="/admin/Sale"
          // subtitle={"$340.5"}
        />
        <Widget
          icon={<MdBookmark className="h-6 w-6" />}
          title={"Purchase"}
          to="/admin/Purchasepage"
          // subtitle={"$642.39"}
        />
        <Widget
          icon={<IoMdBookmarks className="h-7 w-7" />}
          title={"Items"}
          to="/admin/Item"
          // subtitle={"$574.34"}
        />
        <Widget
          icon={<IoMdList className="h-6 w-6" />}
          title={"Party"}
          to="/admin/Party"
        />
      </div>
      {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 3xl:grid-cols-6">
        <Widget2
          icon={<MdBarChart className="h-7 w-7" />}
          title={"General Report"}
          // subtitle={"145"}
        />
        <Widget2
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Pending Payments"}
          // subtitle={"$2433"}
        />
      </div> */}
      
    </div>
  );
};

export default Dashboard;
