import React, { useState } from "react";
import { Select, Option } from "@material-tailwind/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetParty, Group } from "../../../../Redux/Party/action";
import { useForm } from "react-hook-form"
import { PriceGroupAddMaster } from "../../../../Redux/Master/action";
import toast from "react-hot-toast";

function Pricegroupmodal(props) {
    const dispatch =useDispatch()
    const [Pricename,setPricename]=useState()
    const [code,setcode]=useState()
    const TempleId = JSON.parse(localStorage.getItem('Sopanam_TempleId'));
    const UserId = JSON.parse(localStorage.getItem('Sopanam_UserId'));


    useEffect(()=>{
      if(props.Editvalue){
        setPricename(props.Editvalue.Name);
        setcode(props.Editvalue.Code);
        
      }
      },[props.Editvalue])


const HandleSave =()=>{
  if(props.Editvalue){
    dispatch(PriceGroupAddMaster({
      Id:props.Editvalue.Id,
      Name: Pricename,
      Code: code,
      Status: 0,
      Systime: "2024-03-12T07:32:55.565Z",
      UserId: UserId,
      TempleId: TempleId
    }))
  }else{
    if( Pricename && code){
      dispatch(PriceGroupAddMaster({
        Id: 0,
        Name: Pricename,
        Code: code,
        Status: 0,
        Systime: "2024-03-12T07:32:55.565Z",
        UserId: UserId,
        TempleId: TempleId
      }))
    }else{
      toast.error("Fill all the mandatory fields")
    }
 
  }
    
   
}

  return (
    <div>
      <div className="fixed inset-0 z-50 overflow-y-auto ">
        <div className="flex min-h-screen items-center justify-center px-4 text-center">
          {/* Background Overlay */}
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>

          {/* Modal Content */}
          <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl sm:w-4/5 sm:max-w-md">
            {/* Modal Header */}
            <div className="flex items-center justify-between pb-3">
              <h3 className="text-lg font-medium text-gray-900">Insert Price Group</h3>
              <button
                className="text-gray-500 hover:text-gray-600 focus:outline-none"
                onClick={() => {
                  // Handle close modal action
                  props.setModalOpen(!props.ModalOpen);
                }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            <div className="mt-4 ">
             
                  <div className="mb-4 mr-4  flex-1 ">
                    <label
                      htmlFor="Pricename"
                      className="block text-sm font-medium text-gray-700"
                    >
                     Price Group Name <span className="text-red-500">*</span>
                    </label>
                    <input
            className="inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-mauve12 shadow-[0_0_0_1px] shadow-mauve12 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
            id="Pricename"
            value={Pricename}
             onChange={(e) => setPricename(e.target.value)}
          />
                  </div>
                  <div className="mb-4  flex-1">
                    <label
                      htmlFor="code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Code <span className="text-red-500">*</span>
                    </label>
                    <input
            className="inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-mauve12 shadow-[0_0_0_1px] shadow-mauve12 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
            id="code"
            value={code}
             onChange={(e) => setcode(e.target.value)}
          />
                  </div>
               
               
                <div className="mt-5 flex justify-end sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                   onClick={()=>HandleSave()}
                  >
                    Save
                  </button>
                </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricegroupmodal;
