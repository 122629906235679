import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { DiApple } from "react-icons/di";
import { DiAndroid } from "react-icons/di";
import { DiWindows } from "react-icons/di";
import { BsPlusCircleFill } from "react-icons/bs";

import React, { useEffect, useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Progress from "components/progress";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemsModal from "./components/PartyModal";
import { ADDPARTYFail, GetAllParty, deleteparty } from "../../../Redux/Party/action";
import { MdDeleteForever, MdEditNote } from "react-icons/md";
import toast from "react-hot-toast";

const DevelopmentTable = () => {
  const [Editvalue, setEditvalue] = useState();
  const ItemDataValue = useSelector((state) => state.Items.ItemData);
  const PartyData = useSelector((state) => state.Party.GetAllPartyData);
  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const addparty = useSelector((state) => state.Party.addpartydata);
  const dleteparty = useSelector((state) => state.Party.partydelete);

  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GetAllParty(TempleID));
   
  }, [addparty,dleteparty]);

  useEffect(() => {
    if(addparty.Id){
      setModalOpen(!ModalOpen)
      toast.success("Party Added")
      Dispatch(ADDPARTYFail())
    }
   }, [addparty])


 
  const [open, setOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);

useEffect(() => {

}, [Editvalue])


 

  return (
    <div>
      {ModalOpen ? (
        <ItemsModal setModalOpen={setModalOpen} ModalOpen={ModalOpen}  Editvalue={Editvalue}/>
      ) : (
        ""
      )}
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="pb-5 text-xl font-bold text-navy-700 dark:text-white">
          Party Table
          </div>
          <div>
            {" "}
            <button
              data-modal-target="crud-modal"
              data-modal-toggle="crud-modal"
              onClick={() =>{
                setEditvalue("")
                setOpen(!open)
              }
              }
              open={open}
              className={`flex items-center text-xl hover:cursor-pointer ${
                // transparent
                // "bg-none text-white hover:bg-none active:bg-none"
                "mb-2 mr-5 bg-lightPrimary p-2 text-brand-green hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              } linear justify-center rounded-lg font-bold transition duration-200`}
            >
              <BsPlusCircleFill
                onClick={() => {
                  setModalOpen(true);
                }}
                className="h-6 w-6"
              />
            </button>
          </div>
        </div>

        <div className="h-full md:h-[420px] md:overflow-y-auto ">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-left text-sm text-black-500 rtl:text-right dark:text-black-400">
              <thead className="bg-gray-50 text-xs uppercase text-black-700 dark:bg-black-700 dark:text-black-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Code
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Address
                  </th>
                  <th scope="col" className="px-6 py-3">
                    PhoneNumber
                  </th>
                  <th scope="col" className="px-6 py-3">
                    GSTN
                  </th>
                  <th scope="col" className="px-6 py-3">
                    State
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Action
                    </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(PartyData) &&
                  PartyData.map((item, index) => (
                    <tr
                      key={index}
                      className={`border-b ${
                        index % 2 === 0
                          ? "even:bg-gray-50 dark:bg-gray-800"
                          : "odd:bg-white"
                      } ${
                        index % 2 === 0
                          ? "even:bg-gray-800 dark:bg-gray-800"
                          : "odd:bg-gray-900"
                      }`}
                    >
                      <td className="px-6 py-4 text-gray-900">{item.Name}</td>
                      <td className="px-6 py-4">{item.Code}</td>
                      <td className="px-6 py-4">{item.Address}</td>
                      <td className="px-6 py-4">{item.PhoneNumber}</td>
                      <td className="px-6 py-4">{item.GSTN}</td>
                      <td className="px-6 py-4">{item.State}</td>

                      <td className="px-6 py-4 whitespace-nowrap">
                       <button
                            type="button"
                            className="text-green-600  mr-5 "
                            onClick={() =>{
                              setEditvalue(item)
                              setModalOpen(true)
                            }
                            }
                          >
                            <MdEditNote
                              className="mt-1"
                              style={{ fontSize: "1.5rem" }}
                            
                              />
                          </button>
              <button
              type="button"
                className="text-red-600 hover:text-red-900"
                onClick={() => {
                  Dispatch(deleteparty({
                    PartyId:item.Id,
                 
                 }))
                }}
              >
                <MdDeleteForever className="mt-1" style={{ fontSize: "1.5rem" }} />
              </button>
             

            </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DevelopmentTable;
