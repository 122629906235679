export const actionType={
    PARTY:"PARTY",
    PARTY_SUCCESS:"PARTY_SUCCESS",
        PARTY_FAIL:"PARTY_FAIL",

        GETPARTY:"GETPARTY",
    GETPARTY_SUCCESS:"GETPARTY_SUCCESS",
    GETPARTY_FAIL:"GETPARTY_FAIL",


    DELETE_PARTY:"DELETE_PARTY",
    DELETE_PARTY_SUCCESS:"DELETE_PARTY_SUCCESS",
    DELETE_PARTY_FAIL:"DELETE_PARTY_FAIL",
   


     
    ADDPARTY:"ADDPARTY",
    ADDPARTY_SUCCESS:"ADDPARTY_SUCCESS",
    ADDPARTY_FAIL:"ADDPARTY_FAIL",



    
    STATE:"STATE",
    STATE_SUCCESS:"STATE_SUCCESS",
    STATE_FAIL:"STATE_FAIL",


    GETALLPARTY:"GETALLPARTY",
    GETALLPARTY_SUCCESS:" GETALLPARTY_SUCCESS",
    GETALLPARTY_FAIL:" GETALLPARTY_FAIL",

    
}

export const Party = (payload)=>{
    return {
        type:actionType.PARTY,
        payload
    }
}

export const PartySuccess = (payload)=>{
    return {
        type:actionType.PARTY_SUCCESS,
        payload
    }
}

export const PartyFail = (payload)=>{
    return{
        type:actionType.PARTY_FAIL,
        payload
    }
}


export const deleteparty = (payload)=>{
    return {
        type:actionType.DELETE_PARTY,
        payload
    }
}

export const deletepartySuccess = (payload)=>{
    return {
        type:actionType.DELETE_PARTY_SUCCESS,
        payload
    }
}

export const deletepartyFail = (payload)=>{
    return{
        type:actionType.DELETE_PARTY_FAIL,
        payload
    }
}

export const GetParty = (payload)=>{
    return {
        type:actionType.GETPARTY,
        payload
    }
}

export const GetPartySuccess = (payload)=>{
    return {
            type:actionType.GETPARTY_SUCCESS,
            payload
        }
    }

    export const GetPartyFail = (payload)=>{
        return{
            type:actionType.GETPARTY_FAIL,
            payload
        }
    }

    export const Group = (payload)=>{
        return {
            type:actionType.GETGROUP,
            payload
        }
    }
    
    export const GroupSuccess = (payload)=>{
        return {
            type:actionType.GETGROUP_SUCCESS,
            payload
        }
    }
    
    export const GroupFail = (payload)=>{
        return{
            type:actionType.GETGROUP_FAIL,
            payload
        }
    }


    export const ADDPARTY = (payload)=>{
        return {
            type:actionType.ADDPARTY,
            payload
        }
    }
    
    export const ADDPARTYSuccess = (payload)=>{
        return {
                type:actionType.ADDPARTY_SUCCESS,
                payload
            }
        }
    
        export const ADDPARTYFail = (payload)=>{
            return{
                type:actionType.ADDPARTY_FAIL,
                payload
            }
        }



        
export const State = (payload)=>{
    return {
        type:actionType.STATE,
        payload
    }
}

export const StateSuccess = (payload)=>{
    return {
        type:actionType.STATE_SUCCESS,
        payload
    }
}

export const StateFail = (payload)=>{
    return{
        type:actionType.STATE_FAIL,
        payload
    }
}

export const GetAllParty = (payload)=>{
    return {
        type:actionType.GETALLPARTY,
        payload
    }
}
export const GetAllPartySuccess = (payload)=>{
    return {
        type:actionType.GETALLPARTY_SUCCESS,
        payload
    }
}


export const GetAllPartyFail = (payload)=>{
    return {
        type:actionType.GETALLPARTY_FAIL,
        payload
    }
}