import { actionType } from "./action";
export const InitialState = {
  stock: "",
  Recipes: "",
  section:"",
  transaction:'',
  ApprovalStatus:'',
  ApprovallistStatus:'',
};

const ItemReducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionType.STOCK_REPORT:
      return { ...state, stock: "" };
  case actionType.STOCK_REPORT_SUCCESS:
      return { ...state, stock: action.payload.StockList };
  case actionType.STOCK_REPORT_ERROR:
      return { ...state, stock: "error" };

    case actionType.RECIPES_REPORT:
      return { ...state, Recipes: "" };
  case actionType.RECIPES_REPORT_SUCCESS:
      return { ...state, Recipes: action.payload.result };
  case actionType.RECIPES_REPORT_ERROR:
      return { ...state, Recipes: "error" };

    case actionType.SECTION_MAPPER:
      return { ...state, section: "" };
  case actionType.SECTION_MAPPER_SUCCESS:
      return { ...state, section: action.payload.result };
  case actionType.SECTION_MAPPER_ERROR:
      return { ...state, section: "error" };

    case actionType.TRANSACTION_DETAILS:
      return { ...state, transaction: "" };
  case actionType.TRANSACTION_DETAILS_SUCCESS:
      return { ...state, transaction: action.payload.Result};
  case actionType.TRANSACTION_DETAILS_ERROR:
      return { ...state, transaction: "error" };

    case actionType.APPROVAL:
      return { ...state, ApprovalStatus: "" };
  case actionType.APPROVAL_SUCCESS:
      return { ...state, ApprovalStatus: action.payload.Result[0]};
  case actionType.APPROVAL_ERROR:
      return { ...state, ApprovalStatus: "error" };

    case actionType.APPROVAL_LIST:
      return { ...state, ApprovallistStatus: "" };
  case actionType.APPROVAL_LIST_SUCCESS:
      return { ...state, ApprovallistStatus: action.payload.Result};
  case actionType.APPROVAL_LIST_ERROR:
      return { ...state, ApprovallistStatus: "error" };


    default:
      return state;
  }
};
export default ItemReducer;