import axios from "axios";
import { apiurl } from "configs/config";
import ApiClient from "./ApiClient";


class ReportService {
 constructor(callback) {
        this.callback = callback
    }

   
    async StockReport(payload) {
        const response = await axios.get(
            `${apiurl}/api/Transaction/GetAllStock?TempleId=${payload.TempleId}&ToDate=${payload.ToDate}`,
       
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
   
    async RecipesReport(payload) {
        const response = await axios.get(
            `${apiurl}/api/Report/spGetrecipesReport?TempleId=${payload.TempleId}&Date=${payload.Date}&Session=${payload.Session}`,
       
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
    async sectionmapper(payload) {
        console.log("payload",payload);
        const response = await axios.get(
            `${apiurl}/api/Session/GetSessionMapperData?TempleId=${payload.TempleId}`,
       
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
    async TransactionReport(payload) {
        const response = await axios.get(
            `${apiurl}/api/Transaction/GetTransactionDetailsWithType?TempleId=${payload.TempleId}&Type=${payload.Type}&From=${payload.From}&To=${payload.To}`,
       
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
    async approvalList(payload) {
        const response = await axios.get(
            `${apiurl}/api/Transaction/GetTransactionWithTypeAndIsApprove?TempleId=${payload.TempleId}&Type=${payload.Type}&From=${payload.From}&To=${payload.To}`,
       
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
    async approval(payload) {
        
        const response = await axios.post(
            `${apiurl}/api/Master/SetAprove`,payload,
       
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
   
   
    
}
export default new ReportService();